import {SearchIcon} from '@chakra-ui/icons'
import {
    Box,
    Button,
    chakra,
    Input,
    Select,
    Stack,
    Td,
    Text,
    Th,
    Tr,
    VStack,
} from '@chakra-ui/react'
import {useAuth} from 'contexts/AuthContext'
import {graphql, Link, useStaticQuery} from 'gatsby'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {BulletinsCatSelect, ResultsTable} from 'starterComponents'
import {Container} from 'starterUiComponents'
import TechLibraryFiles from '../../components/pdf/tech-library-file'
import PdfFirebaseUrlParser from '../../utils/pdf-firebase-url-parser'
import {
    getPermissionDeniedReason,
    hasPermission,
    PERMISSIONS,
} from '../../utils/Roles'
import useNeedsMembership from '../../hooks/useNeedsMembership'
import Loader from '../../components/ui-components/Loader'
import NotEnoughPermissions from '../../components/NotEnoughPermissions'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
} from '@chakra-ui/react'
import useHashRouteToggle from '../../components/pdf/useHashRouteToggle'


// This page exports a subquery (at the EOF) which is derived from the initial query located in gatsby-config.js. When referencing a table, ex: make_family,
// will need to use syntax allMysqlTableName, ex: allMysqlMakeFamily. This is a GraphSQL related thing, more information can be found here https://graphql.org/learn/queries/#variable-definitions

const BulletinsPage = (props) => {
    const {
        data: {
            allMysqlBulletin: {nodes, id},
        },
    } = props

    const {currentUser, loading} = useAuth();
    const [cat, setCat] = useState(null);
    const [catDisplayName, setCatDisplayName] = useState(null);
    const [calledBy, setCalledBy] = useState(null);
    const [bulletins, setBulletins] = useState(null);
    const searchInputRef = useRef(null);
    const makeSelectInputRef = useRef(null);

    const addToMap = (map, key, bulletinId) => {
        if (!map[key]) {
            map[key] = [];
        }
        map[key].push(bulletinId);
    };

    const bulletins_for_make = {};
    props.data.allMysqlMakeBulletin.nodes.forEach(item => { addToMap(bulletins_for_make, item.make_id, item.bulletin_id); })

    const bulletins_for_make_family = {};
    props.data.allMysqlMakeFamilyBulletin.nodes.forEach(item => { addToMap(bulletins_for_make_family, item.make_family_id, item.bulletin_id); })

    const universal_bulletins_for_make = {};
    props.data.allMysqlMakeUniversalBulletin.nodes.forEach(item => { addToMap(universal_bulletins_for_make, item.make_id, item.bulletin_id); })

    useEffect(() => {
        const getBulletinsByCalledBy = (make_family_id) => {
            return props.data.allMysqlBulletin.nodes.filter((item) => {
                return make_family_id in bulletins_for_make_family && bulletins_for_make_family[make_family_id].includes(item.id_);
            })
        }
        setBulletins(getBulletinsByCalledBy(calledBy));
        searchInputRef?.current && (searchInputRef.current.value = '');
    }, [calledBy, nodes])

    const universalBulletins = props.data.allMysqlBulletin.nodes.filter((item) => !!item.is_universal);

    const transmissionsByCat = (cat) => {
        let make_row = typeof cat === "string" ? JSON.parse(cat) : cat;
        return props.data.allMysqlMakeFamily.nodes.filter((item) => item.make_id === make_row.id_);
    }

    const universalByCat = (cat) => {
        let make_row = typeof cat === "string" ? JSON.parse(cat) : cat;
        return props.data.allMysqlBulletin.nodes.filter(
            (item) => universal_bulletins_for_make[make_row.id_] && universal_bulletins_for_make[make_row.id_].includes(item.id_));
    }

    const universalArray = cat ? universalByCat(cat) : universalBulletins;


    /**
     * Search Bulletins by title of transmission number
     *
     * Searches bulletins (PDF but NO quick references) by `displaytextheader` or `calledby`
     *
     * @param {SyntheticEvent} e - The submit form event
     */
    const onSearch = (e) => {
        e.preventDefault()

        const searchText = searchInputRef.current.value
        if (!searchText) return
        const _bulletins = props.data.allMysqlBulletin.nodes
            .filter(
                (item) =>
                    (item.calledby?.includes(searchText.toUpperCase()) ||
                        item.displaytextheader?.includes(searchText.toUpperCase().replace(/(?<!\-)(E|22)$/, '-$1')) || // Find With or without the dash on things like 4L60-E/4L60E
                        item.displaytextheader?.includes(searchText.toUpperCase().replace('-', '')) ||
                        item.displaytextheader?.includes(searchText) ||
                        item.displaytextheader?.includes(searchText.toUpperCase()) ||
                        item.displaytextheader?.includes(searchText.toLowerCase())
                    ) &&
                    !item.is_quick_reference
            ).sort((b, a) => b.calledby.localeCompare(a.calledby))

        const make_family_ids = props.data.allMysqlMakeFamilyBulletin.nodes
            .filter((item) => {
                const bulletin = _bulletins.find(_bulletin => _bulletin.id_ === item.bulletin_id);
                return bulletin && !bulletin.is_universal && !bulletin.is_quick_reference;
            })
            .map(item => item.make_family_id)
            .filter((value, index, self) => self.indexOf(value) === index);

        const _all_bulletin_ids = props.data.allMysqlMakeFamilyBulletin.nodes
            .filter(item => make_family_ids.includes(item.make_family_id))
            .map(item => item.bulletin_id);

        const _all_bulletins = props.data.allMysqlBulletin.nodes
            .filter(item => !item.is_universal && !item.is_quick_reference && _all_bulletin_ids.includes(item.id_));

        setBulletins(_all_bulletins);
        makeSelectInputRef?.current && (makeSelectInputRef.current.value = '');  // Resets the make dropdown value when searching in text field
        setCat('');
    }

    /**
     * On Category selector click we want to empty the search bulletins input
     * and the transmission field reset.
     *
     * @param {*} cat
     */
    const onUpdateCategorySelector = (cat) => {
        setCalledBy(null)
        setCat(cat)
        searchInputRef?.current && (searchInputRef.current.value = '')
    }
    const showLoaderPage = useNeedsMembership()

    const [pdfModalInfo, setPdfModalInfo] = useState({
        title: null,
        pdfLink: null,
    })
    const [isOpen, toggleOpen] = useHashRouteToggle('#item')

    const openModalWithEmbeddedPDF = (e, pdfLink, title) => {
        toggleOpen(true)
        setPdfModalInfo({
            title,
            pdfLink,
        })
    }

    const closeModalWithEmbeddedPDF = (e, pdfLink, title) => {
        setPdfModalInfo({
            title: null,
            pdfLink: null,
        })
        toggleOpen(false)
    }

    const PdfTdAnchor = ({pdfLink, anchorText, modalTitle}) => {
        return (
            <a
                onClick={(e) => openModalWithEmbeddedPDF(e, pdfLink, modalTitle)}
                style={{cursor: 'pointer'}}
            >
                {anchorText}
            </a>
        )
    }
    const renderTechLibraryItem = (item) => {
        const pdfLink = PdfFirebaseUrlParser.getUrl(item.dataUrl)
        return (
            <Tr key={item.id} sx={{'&:first-of-type': {pt: 5}}}>
                <Td className="text-sm uppercase w-40 min-w-24">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.calledby}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
                <Td className="text-sm w-40 min-w-24">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.returntotext2}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
                <Td className="text-sm min-w-36">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.displaytextheader}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
            </Tr>
        )
    }

    const onUpdateCategoryDisplayName = (catName) => setCatDisplayName(catName)

    if (!hasPermission(currentUser, PERMISSIONS.TECH_LIBRARY) && !loading) {
        const {reason} = getPermissionDeniedReason(
            currentUser,
            PERMISSIONS.MEMBERSHIP_CALLS
        )
        return <NotEnoughPermissions reason={reason}/>
    }

    if (showLoaderPage) {
        return <Loader/>
    }

    return (
        <Container>
            {/* Search bulletins */}
            <Box
                pb="60px"
                pt="40px"
                borderRadius="20px"
                backgroundImage="url(/backpattern.jpg)"
                backgroundSize="cover"
                mb={10}
            >
                <chakra.h1 className="mb-8 text-center text-white uppercase text-l md:text-xxl ">
                    Search Tech Library
                </chakra.h1>
                <form onSubmit={onSearch}>
                    <Container size="md">
                        <Stack spacing={3} direction={{base: 'column', md: 'row'}}>
                            <Input
                                type="text"
                                placeholder="Search by Title or Transmission Number "
                                ref={searchInputRef}
                            />
                            <Button type="submit" variant="outline" leftIcon={<SearchIcon/>}>
                                Search
                            </Button>
                        </Stack>
                    </Container>
                </form>
            </Box>
            {/* Selects */}
            <Stack
                direction={{base: 'column', md: 'row'}}
                className="justify-between font-bold text-l font-heading"
            >
                <Text className="font-bold text-l font-heading">
                    Find Bulletins by Make
                </Text>
                <Box
                    sx={{a: {textDecoration: 'underline'}}}
                    style={{fontSize: '1.15rem'}}
                >
                    <chakra.span className="font-normal text-md">Go to:</chakra.span>
                    {' '}
                    <Link to="/quick-references">Quick References</Link> |{' '}
                    <Link to="/numeric-references">Numeric Reference</Link> |{' '}
                    <Link to="/quarterly-bulletins">Quarterly Bulletins</Link>
                </Box>
            </Stack>
            <Stack
                direction={{base: 'column', md: 'row'}}
                spacing={5}
                className="p-8 mb-5 bg-lightBlue"
                sx={{
                    P: {color: 'white', fontStyle: 'italic', m: 0},
                    '.chakra-stack': {
                        width: {base: '100%', md: '50%'},
                        alignItems: 'flex-start',
                    },
                }}
            >
                <VStack>
                    <Text>Select a Make</Text>
                    <BulletinsCatSelect
                        setCategory={onUpdateCategorySelector}
                        setDisplayCategoryName={onUpdateCategoryDisplayName}
                        selectedCategory={makeSelectInputRef?.current ? makeSelectInputRef?.current : ''}
                        selectRef={makeSelectInputRef}
                    />
                </VStack>
                {cat && (
                    <VStack>
                        <Text>Select a Transmission</Text>
                        <Select
                            placeholder="Select a transmission"
                            layerStyle="input"
                            onChange={(e) => setCalledBy(e.target.value)}
                        >
                            {transmissionsByCat(cat).map((item) => {
                                return (
                                    <option key={item.id} value={item.id_}>
                                        {item.family}
                                    </option>
                                )
                            })}
                        </Select>
                    </VStack>
                )}
            </Stack>

            {/* Results */}
            {searchInputRef?.current?.value && !!bulletins.length && (
                <Text className="text-sm font-bold">
                    Results for "{searchInputRef.current.value}"
                </Text>
            )}
            {(!bulletins || !bulletins.length) && searchInputRef?.current?.value && (
                <Text className="text-sm font-bold">
                    No results for "{searchInputRef.current.value}"
                </Text>
            )}
            <VStack
                spacing={10}
                fontSize="sm"
                fontWeight="normal"
                lineHeight={1.6}
                sx={{a: {fontSize: 'sm', fontWeight: 'normal', lineHeight: 1.6}}}
            >
                {bulletins && bulletins.length && (
                    <ResultsTable
                        sx={{tr: {display: 'block'}}}
                        headers={
                            <>
                                <Th className={"w-40 min-w-24"}>Number</Th>
                                <Th className={"w-40 min-w-24"}>Make</Th>
                                <Th className={"min-w-36"}>Description</Th>
                            </>
                        }
                    >
                        {bulletins &&
                            bulletins.map((item) => {
                                return renderTechLibraryItem(item)
                            })}
                    </ResultsTable>
                )}

                {/* Universal results */}

                {universalArray && universalArray.length > 0 && (
                    <Box
                        className="universal"
                        border="1px solid #ddd"
                        p={4}
                        w="100%"
                        mt={5}
                    >
                        <Text className="text-sm font-bold uppercase">
                            Universal {catDisplayName}
                        </Text>
                        <ResultsTable
                            key={id}
                            sx={{tr: {display: 'block'}}}
                            headers={
                                <>
                                    <Th className={"w-40 min-w-24"}>Number</Th>
                                    <Th className={"w-40 min-w-24"}>Make</Th>
                                    <Th className={"min-w-36"}>Description</Th>
                                </>
                            }
                        >
                            {universalArray &&
                                universalArray.map((item) => {
                                    return renderTechLibraryItem(item)
                                })}
                        </ResultsTable>
                    </Box>
                )}
            </VStack>
            {/* PDF Modal */}
            <Modal
                size="full"
                isOpen={isOpen}
                onClose={closeModalWithEmbeddedPDF}
                className="m-4"
            >
                <ModalContent className="my-0 ">
                    <ModalHeader
                        className="font-bold text-white bg-blue font-heading"
                        style={{fontSize: '1.2em'}}
                    >
                        {pdfModalInfo?.title}
                    </ModalHeader>
                    <ModalCloseButton color="white" style={{fontSize: '24px'}}/>
                    <ModalBody
                        className="flex justify-center content-center h-full p-0 m-0"
                        style={{padding: '0px', margin: '0px'}}
                    >
                        {pdfModalInfo?.pdfLink && (
                            <TechLibraryFiles url={pdfModalInfo?.pdfLink}/>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default BulletinsPage

export const techLibraryQuery = graphql`
    query {
        allMysqlMake(
            sort: { fields: [make], order: ASC }
        ) {
        nodes {
            make
            id
            id_
        }
    }
    allMysqlMakeFamily(
        sort: { fields: [family], order: ASC }
    )   {
        nodes {
            make_id
            family
            id
            id_
        }
    }
    allMysqlBulletin(
        sort: { fields: [displaytextlink], order: ASC }
    ) {
        nodes {
            id
            id_
            calledby
            displaytextlink
            displaytextheader
            old_id
            returntotext2
            dataUrl
            is_universal
            is_quick_reference
        }
    }
    allMysqlMakeFamilyBulletin(
        sort: { fields: [id], order: ASC }
    ) {
        nodes {
            id
            id_
            make_family_id
            bulletin_id
        }
    }
    allMysqlMakeBulletin(
        sort: { fields: [id], order: ASC }
    ) {
        nodes {
            id
            make_id
            bulletin_id
        }
    }
    allMysqlMakeUniversalBulletin(
        sort: { fields: [id], order: ASC }
    ) {
        nodes {
            id
            make_id
            bulletin_id
        }
    }
  }
`
